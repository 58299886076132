import React, { Component } from 'react'
import Formsy from 'formsy-react'

import classNames from 'classnames'

import injectSheet from 'react-jss'
import { Col, Row } from 'reactstrap'
import TicketPicker from '../TicketPicker/TicketPicker'
import moment, { Moment } from 'moment'
import mrkrImg from 'img/marker2.png'
import reqCard from 'img/descargas.jpg'
import { SearchTravelStyle, SearchTravelProps, SearchTravelState } from './types'
import { TicketPickerItem } from '../TicketPicker/types'
import { DatepickerFormsy, SelectFormsy, RadioGroupFormsy } from 'shared/components/formsy'
import { AFormatLabel, AButton, Go, ARadio, AFormatError } from 'shared/components/common'
import { Travel, Ticket } from 'modules/travel/models'

const styles: SearchTravelStyle = () => ({
  datepickerLabel: {
    paddingTop: 5
  }
})

class SearchTravel extends Component<SearchTravelProps, SearchTravelState> {
  startDate: Moment
  maxDate: Moment
  destinationSelect: any = React.createRef()
  form: any = React.createRef()
  state: SearchTravelState

  constructor(props: SearchTravelProps) {
    super(props)
    this.startDate = moment()
    this.maxDate = moment().add(3, 'months')
    this.handleTicketsChange = this.handleTicketsChange.bind(this)
    this.handleTravelType = this.handleTravelType.bind(this)
    this.handleDepartureDate = this.handleDepartureDate.bind(this)
    this.handleOriginChange = this.handleOriginChange.bind(this)
    this.handleDestinationChange = this.handleDestinationChange.bind(this)
    this.onInvalidForm = this.onInvalidForm.bind(this)
    this.onValidForm = this.onValidForm.bind(this)
    this.state = {
      tickets: [],
      ticketsCount: 0,
      isRound: false,
      returnMinDate: moment(),
      canSubmit: false,
      formValid: false,
      openTravel: false,
      onlyYoungers: false,
      isMobile: false
    }
    if (props.special_tickets) {
      this.state.tickets = props.special_tickets.map(t => ({
        id: t.id,
        name: t.name,
        value: t.is_default ? 1 : 0,
        younger_than: t.younger_than
      }))
    }
    this.state.ticketsCount = 1
    this.state.canSubmit = this.state.formValid
  }

  componentDidMount() {
    this.setState({ returnMinDate: this.startDate })
    const isMobileValue = window.innerWidth <= 768
    this.setState({ isMobile: isMobileValue, returnMinDate: this.startDate })

    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    // Eliminar el event listener cuando el componente se desmonte
    window.removeEventListener('resize', this.handleResize)
  }

  onSubmit = (model: any) => {
    const { terminals, special_tickets, onConfirm } = this.props
    const { tickets, openTravel } = this.state
    const { departureDate, returnDate } = model
    let { travelType } = model
    travelType = parseInt(travelType, 10)
    if (terminals) {
      const origin = terminals.find(t => t.id === parseInt(model.origin, 10))
      const destination = terminals.find(t => t.id === parseInt(model.destination, 10))
      let passengers: Ticket[] = []
      if (this.props.quick) {
        const ticketBase = special_tickets.find(st => !!st.is_default)
        travelType = 2
        passengers = [
          {
            id: (ticketBase && ticketBase.id) || 1,
            name: (ticketBase && ticketBase.name) || '',
            ticket: ticketBase || null,
            value: 1
          }
        ]
      } else {
        passengers = tickets.map<Ticket>(t => {
          const special_ticket = special_tickets.find(st => st.id === t.id)
          return {
            ...t,
            ticket: special_ticket || null
          }
        })
      }

      const travel = new Travel({
        travelType,
        origin,
        destination,
        isOpen: openTravel,
        tickets: passengers.filter(p => p.value > 0),
        departure: {
          date: departureDate
        }
      })

      if (returnDate) {
        travel.return = {
          date: returnDate
        }
      }

      this.form.reset()
      this.handleOriginChange('')
      if (onConfirm) {
        onConfirm(travel)
      }
    }
  }

  onInvalidForm() {
    this.setState({ formValid: false, canSubmit: false })
  }

  onValidForm() {
    const { ticketsCount } = this.state
    this.setState({ formValid: true, canSubmit: !!ticketsCount })
  }

  handleTicketsChange(items: TicketPickerItem[], count: number) {
    const { formValid } = this.state
    const youngers = items.reduce((acumulator, current) => {
      return current.younger_than && current.younger_than < 18 ? acumulator + current.value : acumulator + 0
    }, 0)
    if (youngers === count) {
      this.setState({ onlyYoungers: true })
    } else {
      this.setState({ onlyYoungers: false })
    }
    this.setState({ tickets: items, ticketsCount: count, canSubmit: formValid && !!count })
  }

  handleTravelType(val: any) {
    this.setState({ isRound: parseInt(val, 10) === 3 })
  }

  handleDepartureDate(val: Moment) {
    this.setState({ returnMinDate: val })
  }

  handleOriginChange(val: any) {
    let id = val
    id = parseInt(val, 10)
    this.destinationSelect.resetValue()
    this.setState({ selectedOrigin: id, selectedDestination: 0 })
  }
  handleDestinationChange(val: any) {
    let id = val
    id = parseInt(val, 10)
    this.setState({ selectedDestination: id })
  }

  handleOpenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.checked
    this.setState({ openTravel: value })
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 })
  }

  render(): React.ReactNode {
    const { canSubmit, tickets, isRound, returnMinDate, selectedOrigin, ticketsCount, openTravel, onlyYoungers, isMobile } = this.state
    const { terminals, quick, showAppBanner, disabled } = this.props

    let datePickerLabel = 'h6'
    let { classes } = this.props
    classes = classes || {}

    if (showAppBanner) {
      datePickerLabel = classNames(datePickerLabel, classes.datepickerLabel)
    }
    let form
    const departurePicker = (
      <DatepickerFormsy
        name="departureDate"
        label="Salida"
        labelClassName={datePickerLabel}
        value={this.props.departureDate || null}
        onChange={this.handleDepartureDate}
        required
        pickerProps={{
          disabled,
          maxDate: this.maxDate,
          minDate: this.startDate,
          placeholderText: 'Fecha de salida'
        }}
      />
    )
    const leftSide = (
      <>
        <SelectFormsy
          name="origin"
          label={!quick || showAppBanner ? 'ORIGEN' : 'KRIBLET'}
          value={this.props.originId || ''}
          onChange={this.handleOriginChange}
          selectProps={{ disabled, className: 'custom-select2' }}
          labelClassName={!quick ? 'h5' : `${showAppBanner ? 'h5' : 'h6 invisible'}`}
          required
        >
          <option value="">¿De dónde sales?</option>
          {(terminals &&
            terminals.map(t => {
              const excludeTerminals = ['GDL01', 'GML01', 'CUL02', 'LMM02', 'GDL02', 'HMO01']
              if (!excludeTerminals.includes(t.prefix)) {
                return (
                  <option value={t.id} key={t.id}>
                    {t.name}
                  </option>
                )
              }
            })) ||
            null}
        </SelectFormsy>
        {terminals && this.state.selectedOrigin
          ? terminals.map(t => {
              if (t.id === this.state.selectedOrigin) {
                return (
                  <p key={t.id} style={{ color: '#27387e', fontSize: '10px' }}>
                    {' '}
                    <img src={mrkrImg} style={{ width: '20px', height: '30px' }} />
                    {t.address}
                  </p>
                )
              }
            })
          : null}
        <SelectFormsy
          name="destination"
          label={!quick || showAppBanner ? 'Destino' : null}
          value={this.props.destinyId || ''}
          onChange={this.handleDestinationChange}
          ref={(node: any) => {
            this.destinationSelect = node
          }}
          selectProps={{ className: 'custom-select2', disabled: !selectedOrigin || disabled }}
          labelClassName="h5"
          required
        >
          <option value="">¿A dónde viajas?</option>
          {(terminals &&
            terminals.map(t => {
              const terminalOrigin = terminals.find(terminal => terminal.id === this.state.selectedOrigin)
              if (terminalOrigin) {
                const excludeTerminals = ['LMM02', 'GDL01', 'GML01', 'CUL02', 'GDL02', 'HMO01']
                if (terminalOrigin.state_id === 1) {
                  excludeTerminals.push('TIJ02')
                }
                if (t.city_id !== terminalOrigin.city_id && !excludeTerminals.includes(t.prefix)) {
                  return (
                    <option value={t.id} key={t.id} className={classNames({ 'd-none': t.id === selectedOrigin })}>
                      {t.name}
                    </option>
                  )
                }
              }
            })) ||
            null}
        </SelectFormsy>
        {terminals && this.state.selectedDestination
          ? terminals.map(t => {
              if (t.id === this.state.selectedDestination) {
                return (
                  <p key={t.id} style={{ color: '#27387e', fontSize: '10px' }}>
                    {' '}
                    <img src={mrkrImg} style={{ width: '20px', height: '30px' }} />
                    {t.address}{' '}
                  </p>
                )
              }
            })
          : null}
      </>
    )

    if (quick) {
      form = (
        <Row>
          <Col sm={5}>{leftSide}</Col>
          <Col sm={3}>
            {departurePicker}
            <div className="form-group">
              {showAppBanner ? <AFormatLabel className="d-block invisible">KRIBLET</AFormatLabel> : null}
              <AButton variant="med" disabled={!canSubmit || disabled}>
                Consultar Salidas
              </AButton>
            </div>
          </Col>
          {showAppBanner ? (
            <Col sm={4}>
              <Go to="/descarga">
                <img src={reqCard} />
              </Go>
            </Col>
          ) : null}
        </Row>
      )
    } else {
      form = (
        <Row sx={{ width: '90vw', maxWidth: '1200px' }}>
          <Col xs={12} sm={12} md={6}>
            <Row style={{ marginTop: '8px' }}>
              <Col sm={6}>
                <SelectFormsy
                  name="origin"
                  label={!quick || showAppBanner ? 'Origen' : 'KRIBLET'}
                  value={this.props.originId || ''}
                  onChange={this.handleOriginChange}
                  selectProps={{ disabled, className: 'custom-select2' }}
                  labelClassName={!quick ? 'h5' : `${showAppBanner ? 'h5' : 'h6 invisible'}`}
                  required
                >
                  <option value="">¿De dónde sales?</option>
                  {(terminals &&
                    terminals.map(t => {
                      const excludeTerminals = ['GDL01', 'GML01', 'CUL02', 'LMM02', 'GDL02', 'HMO01']
                      if (!excludeTerminals.includes(t.prefix)) {
                        return (
                          <option value={t.id} key={t.id}>
                            {t.name}
                          </option>
                        )
                      }
                    })) ||
                    null}
                </SelectFormsy>
                {terminals && this.state.selectedOrigin
                  ? terminals.map(t => {
                      if (t.id === this.state.selectedOrigin) {
                        return (
                          <p key={t.id} style={{ color: '#27387e', fontSize: '10px' }}>
                            {' '}
                            <img src={mrkrImg} style={{ width: '20px', height: '30px' }} />
                            {t.address}
                          </p>
                        )
                      }
                    })
                  : null}
              </Col>
              <Col sm={6}>
                <SelectFormsy
                  name="destination"
                  label={!quick || showAppBanner ? 'Destino' : null}
                  value={this.props.destinyId || ''}
                  onChange={this.handleDestinationChange}
                  ref={(node: any) => {
                    this.destinationSelect = node
                  }}
                  selectProps={{ className: 'custom-select2', disabled: !selectedOrigin || disabled }}
                  labelClassName="h5"
                  required
                >
                  <option value="">¿A dónde viajas?</option>
                  {(terminals &&
                    terminals.map(t => {
                      const terminalOrigin = terminals.find(terminal => terminal.id === this.state.selectedOrigin)
                      if (terminalOrigin) {
                        const excludeTerminals = ['LMM02', 'GDL01', 'GML01', 'CUL02', 'GDL02', 'HMO01']
                        if (terminalOrigin.state_id === 1) {
                          excludeTerminals.push('TIJ02')
                        }
                        if (t.city_id !== terminalOrigin.city_id && !excludeTerminals.includes(t.prefix)) {
                          return (
                            <option value={t.id} key={t.id} className={classNames({ 'd-none': t.id === selectedOrigin })}>
                              {t.name}
                            </option>
                          )
                        }
                      }
                    })) ||
                    null}
                </SelectFormsy>
                {terminals && this.state.selectedDestination
                  ? terminals.map(t => {
                      if (t.id === this.state.selectedDestination) {
                        return (
                          <p key={t.id} style={{ color: '#27387e', fontSize: '10px' }}>
                            {' '}
                            <img src={mrkrImg} style={{ width: '20px', height: '30px' }} />
                            {t.address}{' '}
                          </p>
                        )
                      }
                    })
                  : null}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="form-group">
                  <TicketPicker
                    disabled={disabled}
                    onChange={this.handleTicketsChange}
                    items={tickets}
                    label="Pasajeros"
                    text="Pasajeros $count"
                  />
                  {!ticketsCount ? <AFormatError helperText="Debe capturar al menos 1 pasajero." /> : null}
                  {ticketsCount && onlyYoungers ? <AFormatError helperText="Debe capturar al menos 1 pasajero mayor de edad." /> : null}
                </div>
                {/* <p>
                  Presentar identificación que lo acredite3
                </p> */}
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Row style={{ height: '90px' }}>
              <Col xs={6} md={4} style={{ marginTop: 8 }}>
                {isRound || (!isRound && !openTravel) || (openTravel && isRound) ? departurePicker : null}
              </Col>
              <Col xs={6} md={4} style={{ marginTop: 8 }}>
                {isRound && !openTravel ? (
                  <DatepickerFormsy
                    name="returnDate"
                    label="Regreso"
                    labelClassName="h6"
                    required
                    pickerProps={{
                      disabled,
                      maxDate: this.maxDate,
                      minDate: returnMinDate,
                      placeholderText: 'Fecha de regreso',
                      openToDate: returnMinDate
                    }}
                  />
                ) : null}
              </Col>
              {!isMobile && (
                <Col sm={12} md={4} style={{ marginTop: 8 }}>
                  <AButton type="submit" disabled={!canSubmit || onlyYoungers}>
                    BUSCAR BOLETOS
                  </AButton>
                </Col>
              )}
            </Row>
            <Row style={{ height: !isMobile ? '90px' : 'auto' }}>
              <Col sm={8} md={8} xs={12}>
                <RadioGroupFormsy name="travelType" value={2} radioProps={{ disabled, inline: true }} onChange={this.handleTravelType}>
                  {!openTravel ? <ARadio label="Viaje sencillo" value={2} /> : null}
                  <ARadio label="Viaje redondo" value={3} />
                </RadioGroupFormsy>
              </Col>
              {!isMobile && (
                <Col sm={12} md={4} style={{ marginTop: 8, textAlign: 'center' }}>
                  <AButton variant="reserve" type="submit" disabled={!canSubmit || onlyYoungers}>
                    RESERVAR
                  </AButton>
                </Col>
              )}
              {isMobile && (
                <Col sm={12} md={4} style={{ marginTop: 8 }}>
                  <AButton type="submit" style={{ backgroundColor: '#d01d56' }} disabled={!canSubmit || onlyYoungers}>
                    BUSCAR BOLETOS
                  </AButton>
                </Col>
              )}
            </Row>
            {/* <Row>
              {isRound ? (
                <Col sm={3} md={4} xs={6}>
                  <AFormatLabel className="mb-3 invisible">OPEN</AFormatLabel>
                  <ACheckbox label="¿Viaje abierto?" labelClassName="mb-3" onChange={this.handleOpenChange} value={openTravel} />
                </Col>
              ) : null}
            </Row> */}
          </Col>
        </Row>
      )
    }

    return (
      <Formsy
        ref={(node: any) => {
          this.form = node
        }}
        onValidSubmit={this.onSubmit}
        onValid={this.onValidForm}
        onInvalid={this.onInvalidForm}
      >
        {form}
      </Formsy>
    )
  }
}
export default injectSheet(styles)(SearchTravel)
