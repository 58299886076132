import React, { Component } from 'react'
import SpinnerMultiple from './SpinnerMultiple'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { TicketPickerItem, TicketPickerProps, TicketPickerState } from './types'
import { AButton } from 'shared/components/common'

const getText = (text: string, items: TicketPickerItem[]) => {
  return text.replace('$count', count(items).toString())
}

const count = (items: TicketPickerItem[]) => {
  return items.reduce((acu, next) => {
    return acu + (next.value || 0)
  }, 0)
}

class TicketPicker extends Component<TicketPickerProps, TicketPickerState> {
  constructor(props: TicketPickerProps) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      modal: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    })
  }

  handleChange(val: number, id: number) {
    const { onChange, items } = this.props
    const newItems = items
    const item = newItems.find(it => id === it.id)
    if (item) {
      item.value = val
    }
    if (onChange) {
      onChange(newItems, count(newItems))
    }
  }

  render() {
    const { inline, items, label, text, disabled } = this.props
    return (
      <div>
        {inline ? (
          <SpinnerMultiple
            items={items.map(item => ({
              label: item.name,
              value: item.value,
              onChange: (val: number) => this.handleChange(val, item.id)
            }))}
          />
        ) : (
          <div>
            <h6 className="big-gray">{label || ''}</h6>
            <button type="button" onClick={this.toggle} className="btn-pasajeros" disabled={disabled}>
              {getText(text || '', items)}
            </button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} centered className="modal-largo">
              <ModalHeader toggle={this.toggle}>
                <label className="big-gray h6">
                  <i className="fas fa-user-plus" style={{ paddingRight: 10 }} /> Seleccione el número de pasajeros
                </label>
              </ModalHeader>
              <ModalBody>
                <SpinnerMultiple
                  items={items.map(item => ({
                    label: item.name,
                    onChange: (val: number) => {
                      this.handleChange(val, item.id)
                    },
                    ...item
                  }))}
                />
              </ModalBody>
              <ModalFooter style={{ justifyContent: 'space-between' }}>
                <p style={{ color: '#27387e', textAlign: 'left', fontSize: '12px' }}>*Presentar identificación que lo acredite</p>
                <AButton style={{ backgroundColor: '#27387e', width: '150px', height: '50px' }} onClick={this.toggle}>
                  Confirmar
                </AButton>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </div>
    )
  }
}

export default TicketPicker
