import React, { Component, Fragment } from 'react'
import { Row, Col, Modal, Container, ModalHeader, ModalBody, UncontrolledTooltip } from 'reactstrap'
import { PromosListProps, PromosListState } from './types'
import { currency } from 'core/utils'
import moment from 'moment'

export class PromosList extends Component<PromosListProps, PromosListState> {
  state: PromosListState = {
    collapse: false
  }

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }))
  }

  render() {
    const { promos, onConfirm } = this.props
    return promos && promos.length ? (
      <>
        <a
          href="#"
          className="text-white"
          onClick={ev => {
            ev.preventDefault()
            this.toggle()
          }}
        >
          <h6 className="text-white">
            <i className="fas fa-tags" /> Ver más promociones
          </h6>
        </a>
        <Modal isOpen={this.state.collapse} size="lg" toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <label className="big-gray h6">
              <i className="fas fa-tags" style={{ paddingRight: 10 }} /> Listado de promociones
            </label>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col sm={12}>
                  <br />
                  <Row>
                    <Col sm={3}>
                      <h5 className="big-blue">Código</h5>
                    </Col>
                    <Col sm={3}>
                      <h5 className="big-blue">Concepto</h5>
                    </Col>
                    <Col sm={3}>
                      <h5 className="big-blue">Vigencia</h5>
                    </Col>
                    <Col sm={3}>
                      <h5 className="big-blue">Descripción</h5>
                    </Col>
                  </Row>
                  {promos.map(promo => (
                    <Fragment key={promo.id}>
                      <Row
                        id={`promoTooltip${promo.id}`}
                        onClick={() => {
                          this.toggle()
                          return onConfirm && onConfirm(promo)
                        }}
                      >
                        <Col sm={12} className="p-0">
                          <hr className="my-0" />
                        </Col>
                        <Col sm={12}>
                          <Row className="promo-list-item">
                            <Col sm={3}>
                              <h6 className="big-gray font-weight-bold">{promo.discount_code}</h6>
                            </Col>
                            <Col sm={3}>
                              <span className="big-gray">
                                - {promo.discount_type === 'direct_amount' ? currency.format(promo.discount) : `${promo.discount}%`}
                              </span>
                            </Col>
                            <Col sm={3}>
                              <span className="big-gray">{moment(promo.until, 'YYYY-MM-DD').format('L')}</span>
                            </Col>
                            <Col sm={3}>
                              <p className="big-gray">{promo.description}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <UncontrolledTooltip placement="bottom" target={`promoTooltip${promo.id}`} delay={0}>
                        Aplicar: {promo.discount_code}
                      </UncontrolledTooltip>
                    </Fragment>
                  ))}
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    ) : null
  }
}

export default PromosList
