import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { PassengersCaptureStepStyle, PassengersCaptureStepProps, PassengersCaptureStepState } from './types'
import { Row, Col } from 'reactstrap'
import TravelSummary from '../../TravelSummary/TravelSummary'
import PassengerForm from '../../PassengerForm/PassengerForm'
import { Passenger } from 'modules/travel/models'
import { isExisty } from 'core/utils'

const styles: PassengersCaptureStepStyle = () => ({
  root: {}
})

const isThereMain = (passengers: Passenger[]) => {
  return passengers.some(p => p.principal_passenger)
}

class PassengersCaptureStep extends Component<PassengersCaptureStepProps, PassengersCaptureStepState> {
  passengers: Passenger[] = []

  state: PassengersCaptureStepState = {}

  constructor(props: PassengersCaptureStepProps) {
    super(props)
  }

  onValidPassenger = (passenger: Passenger, id: number) => {
    this.passengers[id] = new Passenger({
      ...this.props.passengers[id],
      ...passenger
    })
    if (this.passengers.length === this.props.passengers.length) {
      if (this.passengers.length === 1) {
        this.passengers[0].principal_passenger = true
      } else if (!isThereMain(this.passengers)) {
        return this.onInvalidPassenger()
      }
      if (this.props.onValid) {
        this.props.onValid(this.passengers)
      }
    }
  }

  onInvalidPassenger = () => {
    if (this.props.onInvalid) {
      this.props.onInvalid()
    }
  }

  handleMain = (main: number, val: boolean) => {
    let obj: { main?: number } = { main: undefined }
    if (val) {
      obj = { main }
    }
    this.setState(obj)
  }

  handleCustomer = (customer: number, val: boolean) => {
    let obj: { customer?: number } = { customer: undefined }
    if (val) {
      obj = { customer }
    }
    this.setState(obj)
  }

  render() {
    const { travelSummary, passengers, user } = this.props
    const { main, customer } = this.state
    const noMain = passengers.length === 1
    return (
      <Row>
        <Col sm={6} xs={12} className="p-0">
          <h3 className="big-blue">Escriba su información personal</h3>
          <p>
            <i>
              Cada boleto incluye <b>1 equipaje de mano</b> y <b>1 maleta documentada</b> <b>hasta 35KG.</b>
            </i>
          </p>
        </Col>
        <Col sm={6} xs={12} className="p-0">
          {travelSummary ? <TravelSummary {...travelSummary} /> : null}
        </Col>
        {passengers.map((p, i) => (
          <PassengerForm
            noMain={noMain}
            user={user}
            mainDisabled={isExisty(main) && main !== i}
            customerDisabled={isExisty(customer) && customer !== i}
            onMainSelect={this.handleMain}
            onCustomerSelect={this.handleCustomer}
            passenger={p}
            index={i}
            key={i}
            onFormValid={this.onValidPassenger}
            onFormInvalid={this.onInvalidPassenger}
          />
        ))}
        <Col sm={12}>
          <br />
          <br />
          <p className="label">
            <i>
              <span className="text-danger">*</span> NOTA: Los campos marcados son requeridos.
            </i>
          </p>
          <br />
        </Col>
      </Row>
    )
  }
}

export default injectSheet(styles)(PassengersCaptureStep)
