import React, { PureComponent } from 'react'
import { Row, Col } from 'reactstrap'
import injectSheet from 'react-jss'
import { TravelSummaryStyle, TravelSummaryProps } from './types'

const styles: TravelSummaryStyle = () => ({
  root: {}
})

class TravelSummary extends PureComponent<TravelSummaryProps> {
  render() {
    const { bus, origin, destination, departure, arrival } = this.props
    return (
      <Row>
        <Col sm={{ size: 10, offset: 2 }}>
          <h4 className="big-blue">Resumen</h4>
          <Row>
            <Col sm={3}>
              <h5 className="big-pink-op">Autobús</h5>
              <h6 className="big-gray">{bus}</h6>
            </Col>
            <Col sm={9}>
              <h5 className="big-pink-op">Ruta</h5>
              <h6 className="big-gray">
                {origin} <i className="fas fa-arrow-circle-right" /> {destination}
              </h6>
            </Col>
            <Col sm={3}>
              <h5 className="big-pink-op">Salida</h5>
              <h6 className="big-gray">{departure}</h6>
            </Col>
            <Col sm={9} className="col-sm-9">
              <h5 className="big-pink-op">Llegada</h5>
              <h6 className="big-gray">{arrival}</h6>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default injectSheet(styles)(TravelSummary)
