import React, { Component, SFC } from 'react'
import { BusMapProps, BusSeatCellProps } from './types'
import classNames from 'classnames'
import { BusSeat } from 'modules/travel/models/ScheduleInfo'

const BusSeatCell: SFC<BusSeatCellProps> = ({ seat, className, onSelect, selecting }) => {
  const seatClass = seat.selected ? 'selected_seat' : seat.type || 'available_seat'
  return (
    <td className={className}>
      <div
        className={classNames('up-down-seats-padding', {
          hall: seat.no_seat
        })}
      >
        {!seat.no_seat ? (
          <div className={classNames('seat', seatClass, { selecting })} onClick={() => onSelect && onSelect(seat)}>
            <p> {seat.seat}</p>
          </div>
        ) : null}
      </div>
    </td>
  )
}

class BusMap extends Component<BusMapProps> {
  handleSeatSelect = (seat: BusSeat) => {
    const { onSelect } = this.props
    return onSelect && onSelect(seat)
  }
  render() {
    const { bus, selecting } = this.props
    return (
      <div className="bus bus_changed mx-auto">
        <div className="abordo_bus_front" />
        <div className="abordo_bus_body">
          <table>
            <tbody>
              {bus &&
                bus.map((row, ri) => (
                  <tr key={ri}>
                    {row.map((s, si) => (
                      <BusSeatCell
                        key={si}
                        seat={s}
                        onSelect={this.handleSeatSelect}
                        selecting={selecting}
                        className={classNames({
                          'pl-1': !si,
                          'pr-1': si === row.length - 1
                        })}
                      />
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="abordo_bus_back" />
      </div>
    )
  }
}

export default BusMap
