import React, { PureComponent } from 'react'
import { RoutesListStepProps, RoutesListStepStyle } from './types'
import injectSheet from 'react-jss'
import RoutesList from '../../RoutesList/RoutesList'
import { Row, Col, Spinner } from 'reactstrap'
import { Schedule } from 'modules/travel/models'

const styles: RoutesListStepStyle = () => ({
  root: {}
})

const dateFormat = 'dddd[,] DD [de] MMMM [de] YYYY'

class RoutesListStep extends PureComponent<RoutesListStepProps> {
  constructor(props: RoutesListStepProps) {
    super(props)
    this.onSelect = this.onSelect.bind(this)
  }

  onSelect(schedule: Schedule) {
    const { onConfirm } = this.props
    if (onConfirm) {
      onConfirm(schedule)
    }
  }

  render() {
    const { date, schedules, tickets, title, loading, isReturn = false } = this.props
    return (
      <React.Fragment>
        <Row>
          <Col sm={12}>
            <h3 className="big-blue">{title}</h3>
            <br />
            <h6 className="big-pink-op">{date.format(dateFormat)}</h6>
            <br />
          </Col>
        </Row>
        {loading ? (
          <div className="w-100 text-center">
            <Spinner color="danger" />
          </div>
        ) : (
          <RoutesList schedules={schedules} stl={tickets} onSelect={this.onSelect} isReturn={isReturn} />
        )}
      </React.Fragment>
    )
  }
}

export default injectSheet(styles)(RoutesListStep)
