import React, { Component } from 'react'
import { DiscountCodeProps, DiscountCodeState } from './types'
import Formsy from 'formsy-react'
import { InputFormsy } from 'shared/components/formsy'
import { Row, Col, Alert } from 'reactstrap'
import { AButton } from 'shared/components/common'
import { TravelService } from 'modules/travel/services'
import { ApiErrors } from 'core/services/ApiService/types'
import PromosList from '../PromosList/PromosList'
import { Promo } from 'modules/travel/services/TravelService/types'

export class DiscountCode extends Component<DiscountCodeProps, DiscountCodeState> {
  form: any
  state: DiscountCodeState = {}

  componentDidMount() {
    const { default: defaultPromo, stl, travelType, isOpen, customer } = this.props
    if (customer) {
      TravelService.promosList({ stl, travelType, isOpen: !!isOpen }).then(promos => {
        let filtered = promos
        if (defaultPromo) {
          filtered = promos.filter(p => p.discount_code !== defaultPromo)
        }
        this.setState({ promos: filtered })
      })
    }
    if (defaultPromo) {
      this.searchPromoCode(defaultPromo)
    }
  }

  onSubmit = (model: any) => {
    this.searchPromoCode(model.code)
  }

  searchPromoCode = (promo_code: string) => {
    this.setState({ loading: true, error: '' })
    const { customer, travelType, stl, serviceId, amount, passengers, isOpen } = this.props
    TravelService.checkPromoCode(
      { travelType, stl: stl.map(st => st.id), code: promo_code, isOpen: !!isOpen },
      { amount, passengers, id: serviceId, customer_id: customer && customer.id }
    )
      .then(res => {
        if (this.props.onConfirm) {
          this.props.onConfirm(res)
        }
        this.setState({ loading: false, code: promo_code, canSubmit: true })
      })
      .catch(err => {
        const newState: any = { loading: false }
        if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
          newState.error = err.message
        } else if (
          err.error.devMessage &&
          (err.error.devMessage.includes('Discount code is not available') || err.error.devMessage.includes('Discount code not found'))
        ) {
          newState.error = 'Código de descuento inválido.'
        } else {
          newState.error = 'Código de descuento no puede ser aplicado.'
          console.error(err)
        }
        this.setState(newState)
      })
  }

  onSelectedPromo = (promo: Promo) => {
    this.searchPromoCode(promo.discount_code)
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }

  removePromo() {
    const { default: discountDefault } = this.props

    if (discountDefault) {
      return this.searchPromoCode(discountDefault)
    }
    if (this.props.onRemoveDiscount) {
      this.props.onRemoveDiscount()
    }
    this.setState({ code: '' })
    this.form.reset()
  }

  render() {
    const { disabled, default: discountDefault } = this.props
    const { canSubmit, loading, code, error } = this.state
    return (
      <Formsy
        ref={(node: any) => {
          this.form = node
        }}
        onValidSubmit={this.onSubmit}
        onValid={this.onValidForm}
        onInvalid={this.onInvalidForm}
      >
        {error ? (
          <Alert color="danger" className="mx-auto" style={{ maxWidth: 360 }}>
            {error}
          </Alert>
        ) : null}
        <Row className="descuentos p-4 mx-auto" style={{ maxWidth: 360 }}>
          {code ? (
            <Col sm={12}>
              <h6>
                Promoción actual: <b>{code}</b>{' '}
                {code !== discountDefault ? <i className="fa fa-times pointer" onClick={() => this.removePromo()} /> : null}
              </h6>
              <hr className="divider" />
            </Col>
          ) : null}
          <Col sm={12} xs={12}>
            <h4>Código de promoción</h4>
            <InputFormsy
              name="code"
              inputProps={{
                disabled,
                className: 'rastrear-search',
                placeholder: 'Ingresa tu código de promoción',
                uppercase: true,
                regex: /^[A-Z0-9]*$/
              }}
              required={!code}
            />
            <AButton variant="pink" className="w-100" disabled={!canSubmit || disabled || loading}>
              {loading ? (
                <>
                  <i className="fa fa-spin fa-spinner" /> Validando...
                </>
              ) : (
                'Aplicar promoción'
              )}
            </AButton>
          </Col>
          <Col sm={12} className="text-center">
            <PromosList promos={this.state.promos} onConfirm={this.onSelectedPromo} />
          </Col>
        </Row>
      </Formsy>
    )
  }
}

export default DiscountCode
