import * as React from 'react'

import { ABanner } from 'shared/components/common'

import { Container, Row, Col } from 'reactstrap'
import { isExisty, currency } from 'core/utils'

import { TravelInfoProps, TravelHeaderProps } from './types'

const passengersNumber = (num: number) => {
  if (!isExisty(num)) {
    return ''
  }
  return `${num > 1 ? 'Pasajeros' : 'Pasajero'}`
}

const TravelInfo: React.SFC<TravelInfoProps> = ({ passengers, price, travelType, origin, destination }) => {
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <span className="white-titulo">{origin}</span>
          <i className="fas fa-arrow-circle-right fa-2x" style={{ color: 'white', paddingRight: 10, paddingLeft: 10 }} />
          <span className="white-titulo">{destination}</span>
        </Col>
        <Col sm={12} className="resumen-datos">
          <span>
            <b>{passengers || 0}</b> {passengersNumber(passengers || 0)}
          </span>
          <div className="linea" />
          <span>{travelType}</span>
          {price ? (
            <React.Fragment>
              <div className="linea" />
              <span>
                Total{' '}
                <span>
                  <b>{currency.format(price)}</b>
                </span>
              </span>
            </React.Fragment>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}

class TravelHeader extends React.PureComponent<TravelHeaderProps> {
  render() {
    const { title, passengers, price, travelType, origin, destination } = this.props
    return (
      <ABanner
        title={title || ''}
        titleSize={7}
        titleClassName="big-white-op"
        content={<TravelInfo {...{ passengers, price, travelType, origin, destination }} />}
      />
    )
  }
}

export default TravelHeader
