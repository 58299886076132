import React, { Component } from 'react'
import Formsy from 'formsy-react'
import classNames from 'classnames'
import { Col, Row } from 'reactstrap'
import { PassengerFormProps, PassengerFormStyle, PassengerFormState } from './types'
import { InputFormsy, RadioGroupFormsy, DatepickerFormsy, CheckboxFormsy } from 'shared/components/formsy'
import injectSheet from 'react-jss'
import _ from 'lodash'
import { ARadio, AFormatLabel } from 'shared/components/common'
import moment from 'moment'

const styles: PassengerFormStyle = () => ({
  radioLabel: {
    marginBottom: 20
  }
})

class PassengersForm extends Component<PassengerFormProps, PassengerFormState> {
  form: any
  state: PassengerFormState = {}
  handleUserData = (ev: boolean, i: number) => {
    const { onCustomerSelect, user } = this.props
    this.setState({ isCustomer: ev })
    let newForm: any = {}
    if (ev && user && user.customer) {
      newForm = { ...user.customer, birthday: moment(user.customer.birthday, 'YYYY-MM-DD') }
    }
    this.form.reset(newForm)
    return onCustomerSelect && onCustomerSelect(i, ev)
  }

  handleValidForm = () => {
    if (this.props.onFormValid && this.form) {
      const passenger = { ...this.form.getModel(), id: this.props.index }
      this.props.onFormValid(passenger, this.props.index)
    }
  }

  render() {
    const { index, user, passenger, noMain, onFormInvalid, mainDisabled, customerDisabled, onMainSelect, classes = {} } = this.props
    const { isCustomer } = this.state
    const { max_date, min_date, special_ticket } = passenger
    return (
      <Col sm={6} xs={12} className="mt-4">
        <Formsy
          className="p-0"
          ref={(node: any) => {
            this.form = node
          }}
          onValid={this.handleValidForm}
          onInvalid={onFormInvalid}
        >
          <h4 className="big-pink-op">Pasajero {index + 1}</h4>
          <h5 className={classNames(classes.radioLabel, 'big-blue')}>{special_ticket && special_ticket.name}</h5>
          <Row>
            <Col sm={6}>
              <InputFormsy
                label="Nombre(s):"
                name="first_name"
                inputProps={{ wrapperClassName: 'blueplace', placeholder: 'Escribe tu(s) nombre(s)', readOnly: isCustomer }}
                required
                validations={{ isSpecialWords: true }}
                validationErrors={{ isSpecialWords: 'Este campo solo acepta letras' }}
              />
            </Col>
            <Col sm={6} className="form-group">
              {user ? (
                <React.Fragment>
                  <AFormatLabel className={classNames(classes.radioLabel, 'invisible')}>KRIBLET</AFormatLabel>
                  <CheckboxFormsy
                    label="Usar mis datos"
                    name="is_customer"
                    onChange={(ev: boolean) => {
                      this.handleUserData(ev, index)
                    }}
                    value={false}
                    checkboxProps={{ disabled: customerDisabled }}
                  />
                </React.Fragment>
              ) : null}
            </Col>
            <Col sm={6}>
              <InputFormsy
                label="Apellido(s):"
                name="last_name"
                inputProps={{ wrapperClassName: 'blueplace', placeholder: 'Escribe tu(s) apellido(s)', readOnly: isCustomer }}
                required
                validations={{ isSpecialWords: true }}
                validationErrors={{ isSpecialWords: 'Este campo solo acepta letras' }}
              />
            </Col>
            <Col sm={6}>
              <RadioGroupFormsy
                name="gender"
                label="Sexo"
                labelClassName={classes.radioLabel}
                value="m"
                radioProps={{ inline: true, disabled: isCustomer }}
              >
                <ARadio label="Masculino" value={'m'} />
                <ARadio label="Femenino" value={'f'} />
              </RadioGroupFormsy>
            </Col>
            <Col sm={6}>
              <DatepickerFormsy
                name="birthday"
                label="Fecha de nacimiento:"
                value={null}
                required
                pickerProps={{
                  maxDate: max_date,
                  minDate: min_date,
                  showYearDropdown: true,
                  scrollableYearDropdown: true,
                  yearDropdownItemNumber: 120,
                  showMonthDropdown: true,
                  readOnly: isCustomer,
                  placeholderText: 'Elige tu fecha de nacimiento'
                }}
              />
            </Col>
            {!noMain ? (
              <Col sm={6}>
                <AFormatLabel className={classNames(classes.radioLabel, 'invisible')}>KRIBLET</AFormatLabel>
                <CheckboxFormsy
                  label="Pasajero principal"
                  name="principal_passenger"
                  onChange={(ev: boolean) => {
                    return onMainSelect && onMainSelect(index, ev)
                  }}
                  value={false}
                  checkboxProps={{ disabled: mainDisabled }}
                />
              </Col>
            ) : null}
          </Row>
        </Formsy>
      </Col>
    )
  }
}

export default injectSheet(styles)(PassengersForm)
