import React, { Component } from 'react'
import { Col, Row, Container } from 'reactstrap'
import { IASpinnerProps } from 'shared/components/common/ASpinner/types'
import { ASpinner } from 'shared/components/common'

interface SpinnerMultipleProps {
  items: IASpinnerProps[]
}

class SpinnerMultiple extends Component<SpinnerMultipleProps> {
  render() {
    const { items } = this.props
    return (
      <Container>
        <Row>
          {items.map((item, index) => {
            return (
              <Col sm={4} key={index}>
                <ASpinner {...item} />
              </Col>
            )
          })}
        </Row>
      </Container>
    )
  }
}

export default SpinnerMultiple
