import React, { PureComponent } from 'react'
import { PassengerSeatProps } from './types'
import { Col } from 'reactstrap'

import classNames from 'classnames'

class PassengerSeat extends PureComponent<PassengerSeatProps> {
  handleSelect = (ev: any) => {
    ev.preventDefault()
    const { onSelect, passenger } = this.props
    return onSelect && onSelect(passenger)
  }

  render() {
    const { passenger, showDivider, selecting, seat } = this.props
    return (
      <Col className="passenger-seat col-xs-4 selectionBusLarge" sm={12} lg={4}>
        <h4 className="big-pink-op">{passenger.full_name}</h4>
        <h4 className={classNames('big-gray', { selecting })}>{seat ? `Asiento ${seat.seat}` : 'No asignado'}</h4>
        <a href="#" className="seatBtn" onClick={this.handleSelect}>
          {selecting ? 'Eligiendo...' : `${!seat ? 'Seleccionar' : 'Cambiar'} asiento`}
        </a>
        {!showDivider ? <hr className="divider" /> : null}
      </Col>
    )
  }
}

export default PassengerSeat
