import React, { Component, SFC, Fragment } from 'react'
import withSizes from 'react-sizes'
import { Row, Col } from 'reactstrap'
import { parseDate0, currency, humanDiff } from 'core/utils'

import selectImg from 'img/vectores/boleto.svg'
import { RoutesListProps, AvailableTicketProps, StopTemplateProps, ScheduleTemplateProps } from './types'
import { AButton } from 'shared/components/common'
import { Schedule } from 'modules/travel/models'

import moduleCss from './RoutesList.module.css'

const EmptyTemplate: SFC = () => {
  return (
    <Row>
      <Col sm={12} className="text-center">
        <h5 className="big-gray">No se encontrarón resultados</h5>
      </Col>
    </Row>
  )
}

const stopsNumber = (num?: number) => {
  if (!num) {
    return 'sin escalas'
  }
  return `${num} ${num > 1 ? 'escalas' : 'escala'}`
}

const AvailableTicketTemplate: SFC<AvailableTicketProps> = ({ name, cost }) => {
  return (
    <Row>
      <Col sm={6} xs={6}>
        <h6 className="big-gray">{name}:</h6>
      </Col>
      <Col sm={6} xs={6}>
        <h6 className="big-pink-op"> {currency.format(cost)}</h6>
      </Col>
    </Row>
  )
}

const StopTemplate: SFC<StopTemplateProps> = ({ stop, index }) => {
  return (
    <React.Fragment>
      <Col sm={6}>
        <h6 className="big-gray">Escala {index}</h6>
      </Col>
      <Col sm={6}>
        <h6 className="big-pink-op">
          {stop.terminal_name}, {stop.time} min.
        </h6>
      </Col>
    </React.Fragment>
  )
}

const ScheduleTemplate: SFC<ScheduleTemplateProps> = ({ schedule, stl, onSelect, index, indexLimit, quick, isReturn = false }) => {
  const scheduleInstance = new Schedule(schedule)
  const { departure_date, arrival_date, prices, available_tickets, available_seatings, stops, status_hide } = scheduleInstance
  if (stops.length > 1) {
    stops.map((s, i) => {
      if (s.same_city === 1) {
        stops.splice(i, 1)
      }
    })
  }

  const stopsN = stops.length - 1
  const total_amount = scheduleInstance.travelCost(stl, isReturn)
  const amount = scheduleInstance.travelCostWithoutDiscount(stl)
  return (
    <React.Fragment>
      <div>
        {status_hide === 1 || available_seatings === 0 ? (
          <div className="schedule-wrapper">
            <h4 className="schedule-front m-0" style={{ color: '#27387e' }} />
            <Row className="schedule-back">
              <Col sm={2} style={{ filter: 'opacity(50%)' }}>
                {!quick ? (
                  <div className="wrapper-stop-logo d-inline-block m-0" />
                ) : (
                  <React.Fragment>
                    <Row>
                      <Col sm={8}>
                        <p className="label">{stopsNumber(stopsN)}</p>
                      </Col>
                      <Col sm={2}>
                        {stopsN ? (
                          <div className="wrapper2 d-inline-block m-0" style={{ zIndex: (indexLimit || 99999) - (index || 0) }}>
                            <div className="tooltip2">
                              <Row>
                                <Col sm={12} className="text-center">
                                  <h6 className="big-blue">{stopsNumber(stopsN)}</h6>
                                </Col>
                                {stops.map((s, i) => {
                                  if (i === stopsN) {
                                    return null
                                  }
                                  return (
                                    <StopTemplate
                                      key={i}
                                      stop={{ terminal_name: s.terminal_destiny_name, time: s.terminal_destiny_time_checkpoint || 0 }}
                                      index={i + 1}
                                    />
                                  )
                                })}
                              </Row>
                            </div>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </Col>
              <Col sm={2} style={{ filter: 'opacity(50%)' }}>
                <h6 className="big-gray mb-0">{parseDate0(departure_date, 'L')}</h6>
                <h5 className="big-gray mb-3">{parseDate0(departure_date, 'hh:mm A')}</h5>
                {quick ? <p className="label">Estimada: {humanDiff(departure_date, arrival_date)}</p> : null}
              </Col>
              <Col sm={2} style={{ filter: 'opacity(50%)' }}>
                <h6 className="big-gray mb-0">{parseDate0(arrival_date, 'L')}</h6>
                <h5 className="big-gray m-0">{parseDate0(arrival_date, 'hh:mm A')}</h5>
              </Col>
              <Col sm={2} style={{ filter: 'opacity(50%)' }}>
                {total_amount < amount ? (
                  <h6 className="big-gray mb-0" style={{ textDecoration: 'line-through' }}>
                    {currency.format(scheduleInstance.travelCostWithoutDiscount(stl))}
                  </h6>
                ) : null}
                <h5 className="big-gray font-weight-bold" style={{ color: '#d01d56' }}>
                  {currency.format(scheduleInstance.travelCost(stl, isReturn))}
                </h5>
              </Col>
              <Col sm={2}>
                <h5 className="text-center" style={{ color: '#d01d56' }}>
                  {available_seatings === 0 || status_hide === 1 ? 'No disponibles' : available_seatings}
                </h5>
              </Col>
              <Col sm={2}>
                {available_seatings === 0 || status_hide === 1 ? (
                  <>
                    <h5 className="m-0 text-center" style={{ color: '#d01d56' }}>
                      AGOTADO
                    </h5>
                  </>
                ) : (
                  <AButton
                    variant="pink"
                    className="selectSchedule"
                    onClick={() => {
                      if (onSelect) {
                        onSelect(schedule)
                      }
                    }}
                  >
                    Seleccionar viaje{quick ? <img src={selectImg} /> : null}
                  </AButton>
                )}
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row>
              <Col sm={2}>
                {!quick ? (
                  <div className="wrapper-stop-logo d-inline-block m-0" />
                ) : (
                  <React.Fragment>
                    <Row>
                      <Col sm={8}>
                        <p className="label">{stopsNumber(stopsN)}</p>
                      </Col>
                      <Col sm={2}>
                        {stopsN ? (
                          <div className="wrapper2 d-inline-block m-0" style={{ zIndex: (indexLimit || 99999) - (index || 0) }}>
                            <div className="tooltip2">
                              <Row>
                                <Col sm={12} className="text-center">
                                  <h6 className="big-blue">{stopsNumber(stopsN)}</h6>
                                </Col>
                                {stops.map((s, i) => {
                                  if (i === stopsN) {
                                    return null
                                  }
                                  return (
                                    <StopTemplate
                                      key={i}
                                      stop={{ terminal_name: s.terminal_destiny_name, time: s.terminal_destiny_time_checkpoint || 0 }}
                                      index={i + 1}
                                    />
                                  )
                                })}
                              </Row>
                            </div>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </Col>
              <Col sm={2}>
                <h6 className="big-gray mb-0">{parseDate0(departure_date, 'L')}</h6>
                <h5 className="big-gray mb-3">{parseDate0(departure_date, 'hh:mm A')}</h5>
                {quick ? <p className="label">Estimada: {humanDiff(departure_date, arrival_date)}</p> : null}
              </Col>
              <Col sm={2}>
                <h6 className="big-gray mb-0">{parseDate0(arrival_date, 'L')}</h6>
                <h5 className="big-gray m-0">{parseDate0(arrival_date, 'hh:mm A')}</h5>
              </Col>
              <Col sm={2}>
                <div className="tooltip-wrapper" style={{ zIndex: (indexLimit || 99999) - (index || 0) }}>
                  {total_amount < amount ? (
                    <h6 className="big-gray mb-0" style={{ textDecoration: 'line-through' }}>
                      {currency.format(scheduleInstance.travelCostWithoutDiscount(stl))}
                    </h6>
                  ) : null}
                  <h5 className="big-gray font-weight-bold" style={{ color: '#d01d56' }}>
                    {currency.format(scheduleInstance.travelCost(stl, isReturn))}
                  </h5>
                  <div className="tooltip">
                    {prices
                      .filter(p => p.origin_allowed && p.origin_allowed.includes('web'))
                      .map((p, i) => {
                        const available_ticket = available_tickets.find(t => p.special_ticket_id === t.id)
                        return (
                          <AvailableTicketTemplate
                            key={i}
                            name={p.special_ticket_name}
                            cost={p.total_amount}
                            available={available_ticket ? available_ticket.available_tickets : available_seatings}
                          />
                        )
                      })}
                  </div>
                </div>
              </Col>
              <Col sm={2}>
                <div className="tooltip-wrapper-seat" style={{ zIndex: (indexLimit || 99999) - (index || 0) }}>
                  <div className="tooltip">
                    <Row>
                      <h5 className="big-pink-op m-0 text-center">
                        {available_seatings} {available_seatings !== 1 ? 'asientos disponibles' : 'asiento disponible'}
                      </h5>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col sm={2}>
                {available_seatings === 0 || status_hide === 1 ? (
                  <>
                    <h5 className="m-0 text-center" style={{ color: '#d01d56' }}>
                      AGOTADO
                    </h5>
                  </>
                ) : (
                  <AButton
                    variant="pink"
                    className="selectSchedule"
                    onClick={() => {
                      if (onSelect) {
                        onSelect(schedule)
                      }
                    }}
                  >
                    Seleccionar viaje{quick ? <img src={selectImg} /> : null}
                  </AButton>
                )}
              </Col>
            </Row>
            {!quick ? (
              <Row style={{ marginTop: -20, marginBottom: 10 }}>
                <Col sm={2}>
                  <p className="label d-inline-block w-50">{stopsNumber(stopsN)}</p>
                  {stopsN ? (
                    <div className="wrapper2 d-inline-block m-0" style={{ zIndex: (indexLimit || 99999) - (index || 0) }}>
                      <div className="tooltip2">
                        <Row>
                          <Col sm={12} className="text-center">
                            <h6 className="big-blue">{stopsNumber(stopsN)}</h6>
                          </Col>
                          {stops.map((s, i) => {
                            if (i === stopsN) {
                              return null
                            }
                            return (
                              <StopTemplate
                                key={i}
                                stop={{ terminal_name: s.terminal_destiny_name, time: s.terminal_destiny_time_checkpoint || 0 }}
                                index={i + 1}
                              />
                            )
                          })}
                        </Row>
                      </div>
                    </div>
                  ) : null}
                </Col>
                <Col sm={2}>
                  <p className="label">Estimada: {humanDiff(departure_date, arrival_date)}</p>
                </Col>
              </Row>
            ) : null}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const ScheduleMobileTemplate: SFC<ScheduleTemplateProps> = ({ schedule, stl, onSelect, index, indexLimit, isReturn = false }) => {
  const scheduleInstance = new Schedule(schedule)
  const { departure_date, arrival_date, available_seatings, stops, status_hide } = scheduleInstance

  if (stops.length > 1) {
    stops.map((s, i) => {
      if (s.same_city === 1) {
        stops.splice(i, 1)
      }
    })
  }

  const stopsN = stops.length - 1
  const total_amount = scheduleInstance.travelCost(stl, isReturn)
  const amount = scheduleInstance.travelCostWithoutDiscount(stl)
  return (
    <React.Fragment>
      {available_seatings === 0 || status_hide === 1 ? (
        <div className="schedule-wrapper">
          <h4 className="schedule-front-mobile m-0 " style={{ color: '#27387e' }} />
          <Row className="schedule-back" style={{ filter: 'opacity(50%)' }}>
            <Col xs={3} className="d-flex align-items-center">
              <h5 className="big-blue">Autobus</h5>
            </Col>
            <Col xs={9}>
              <Col className="d-flex align-items-center">
                <div className="wrapper-stop-logo d-inline-block m-0  ml-3" />
                {stopsN ? (
                  <div className="wrapper2 d-inline-block m-0 ml-3" style={{ zIndex: (indexLimit || 99999) - (index || 0) }}>
                    <div className="tooltip2">
                      <Row>
                        <Col sm={12} className="text-center">
                          <h6 className="big-blue">{stopsNumber(stopsN)}</h6>
                        </Col>
                        {stops.map((s, i) => {
                          if (i === stopsN) {
                            return null
                          }
                          return (
                            <StopTemplate
                              key={i}
                              stop={{ terminal_name: s.terminal_destiny_name, time: s.terminal_destiny_time_checkpoint || 0 }}
                              index={i + 1}
                            />
                          )
                        })}
                      </Row>
                    </div>
                  </div>
                ) : null}
              </Col>
              <Col>
                <span>{stopsNumber(stopsN)}</span>
              </Col>
            </Col>
            <hr className={moduleCss.hrRoutes} />
            <Col xs={3} className="d-flex align-items-center">
              <h5 className="big-blue">Salida</h5>
            </Col>
            <Col xs={9}>
              <h5 className="big-gray">{parseDate0(departure_date, 'L hh:mm A')}</h5>
              <span>Estimada {humanDiff(departure_date, arrival_date)}</span>
            </Col>
            <hr className={moduleCss.hrRoutes} />
            <Col xs={3} className="d-flex align-items-center">
              <h5 className="big-blue">Llegada</h5>
            </Col>
            <Col xs={9}>
              <h5 className="big-gray">{parseDate0(arrival_date, 'L hh:mm A')}</h5>
            </Col>
            <hr className={moduleCss.hrRoutes} />
            <Col xs={3} className="d-flex align-items-center">
              <h5 className="big-blue">Precio</h5>
            </Col>
            <Col xs={9}>
              {total_amount < amount ? (
                <h6 className="big-gray mb-0" style={{ textDecoration: 'line-through' }}>
                  {currency.format(scheduleInstance.travelCostWithoutDiscount(stl))}
                </h6>
              ) : null}
              <h5 className="big-gray font-weight-bold" style={{ color: '#d01d56' }}>
                {currency.format(scheduleInstance.travelCost(stl, isReturn))}
              </h5>
            </Col>
            <hr className={moduleCss.hrRoutes} />
            <Col xs={3} className="d-flex align-items-center">
              <h5 className="big-blue">Asientos</h5>
            </Col>
            <Col xs={9}>
              <h6 className="big-gray font-weight-bold" style={{ color: '#d01d56' }}>
                {available_seatings === 0 || status_hide === 1 ? 'No disponibles' : available_seatings}
              </h6>
            </Col>
            <hr className={moduleCss.hrRoutes} />
            <Col sm={12} className="text-center">
              <br />
              {available_seatings === 0 || status_hide === 1 ? (
                <>
                  <h5 className="m-0 text-center" style={{ color: '#d01d56' }}>
                    AGOTADO
                  </h5>
                </>
              ) : (
                <AButton
                  variant="pink"
                  className="selectSchedule w-100 font-weight-bold"
                  onClick={() => {
                    if (onSelect) {
                      onSelect(schedule)
                    }
                  }}
                >
                  Seleccionar viaje
                </AButton>
              )}
            </Col>
            <hr className={moduleCss.hrRoutes} />
          </Row>
        </div>
      ) : (
        <Row>
          <Col xs={3} className="d-flex align-items-center">
            <h5 className="big-blue">Autobus</h5>
          </Col>
          <Col xs={9}>
            <Col className="d-flex align-items-center">
              <div className="wrapper-stop-logo d-inline-block m-0  ml-3" />
              {stopsN ? (
                <div className="wrapper2 d-inline-block m-0 ml-3" style={{ zIndex: (indexLimit || 99999) - (index || 0) }}>
                  <div className="tooltip2">
                    <Row>
                      <Col sm={12} className="text-center">
                        <h6 className="big-blue">{stopsNumber(stopsN)}</h6>
                      </Col>
                      {stops.map((s, i) => {
                        if (i === stopsN) {
                          return null
                        }
                        return (
                          <StopTemplate
                            key={i}
                            stop={{ terminal_name: s.terminal_destiny_name, time: s.terminal_destiny_time_checkpoint || 0 }}
                            index={i + 1}
                          />
                        )
                      })}
                    </Row>
                  </div>
                </div>
              ) : null}
            </Col>
            <Col>
              <span>{stopsNumber(stopsN)}</span>
            </Col>
          </Col>
          <hr className={moduleCss.hrRoutes} />
          <Col xs={3} className="d-flex align-items-center">
            <h5 className="big-blue">Salida</h5>
          </Col>
          <Col xs={9}>
            <h5 className="big-gray">{parseDate0(departure_date, 'L hh:mm A')}</h5>
            <span>Estimada {humanDiff(departure_date, arrival_date)}</span>
          </Col>
          <hr className={moduleCss.hrRoutes} />
          <Col xs={3} className="d-flex align-items-center">
            <h5 className="big-blue">Llegada</h5>
          </Col>
          <Col xs={9}>
            <h5 className="big-gray">{parseDate0(arrival_date, 'L hh:mm A')}</h5>
          </Col>
          <hr className={moduleCss.hrRoutes} />
          <Col xs={3} className="d-flex align-items-center">
            <h5 className="big-blue">Precio</h5>
          </Col>
          <Col xs={9}>
            {total_amount < amount ? (
              <h6 className="big-gray mb-0" style={{ textDecoration: 'line-through' }}>
                {currency.format(scheduleInstance.travelCostWithoutDiscount(stl))}
              </h6>
            ) : null}
            <h5 className="big-gray font-weight-bold" style={{ color: '#d01d56' }}>
              {currency.format(scheduleInstance.travelCost(stl, isReturn))}
            </h5>
          </Col>
          <hr className={moduleCss.hrRoutes} />
          <Col xs={3} className="d-flex align-items-center">
            <h5 className="big-blue">Asientos</h5>
          </Col>
          <Col xs={9}>
            <h5 className="big-gray font-weight-bold" style={{ color: '#d01d56' }}>
              {available_seatings === 0 || status_hide === 1 ? 'No disponibles' : available_seatings}
            </h5>
          </Col>
          <hr className={moduleCss.hrRoutes} />
          <Col sm={12} className="text-center">
            <br />
            {available_seatings === 0 || status_hide === 1 ? (
              <>
                <h5 className="m-0 text-center" style={{ color: '#d01d56' }}>
                  AGOTADO
                </h5>
              </>
            ) : (
              <AButton
                variant="pink"
                className="selectSchedule w-100 font-weight-bold"
                onClick={() => {
                  if (onSelect) {
                    onSelect(schedule)
                  }
                }}
              >
                Seleccionar viaje
              </AButton>
            )}
          </Col>
          <hr className={moduleCss.hrRoutes} />
        </Row>
      )}
    </React.Fragment>
  )
}

class RoutesList extends Component<RoutesListProps> {
  headers = ['Autobus', 'Salida', 'Llegada', 'Precio', 'Asientos', 'Comprar']
  constructor(props: RoutesListProps) {
    super(props)
    if (props.quick) {
      this.headers = ['Tipo de viaje', 'Hora de salida', 'Hora de llegada', 'Precio', 'Asientos', 'Comprar']
    }
  }

  render() {
    const { schedules, stl, onSelect, quick, isReturn } = this.props
    return (
      <section>
        {!this.props.isMobile ? (
          <Fragment>
            <Row>
              {this.headers.map((h, i) => (
                <Col key={i} sm={2}>
                  <h5 className="big-blue">{h}</h5>
                </Col>
              ))}
            </Row>
            <div className="mt-4 mb-4">
              {schedules && schedules.length ? (
                schedules.map((s, i) => (
                  <ScheduleTemplate
                    key={i}
                    index={i}
                    indexLimit={schedules.length + 1}
                    stl={stl || []}
                    schedule={s}
                    onSelect={onSelect}
                    quick={quick}
                    isReturn={isReturn}
                  />
                ))
              ) : (
                <EmptyTemplate />
              )}
            </div>
          </Fragment>
        ) : schedules && schedules.length ? (
          schedules.map((s, i) => (
            <ScheduleMobileTemplate
              key={i}
              index={i}
              indexLimit={schedules.length + 1}
              stl={stl || []}
              schedule={s}
              onSelect={onSelect}
              quick={quick}
              isReturn={isReturn}
            />
          ))
        ) : (
          <EmptyTemplate />
        )}
      </section>
    )
  }
}

const mapSizesToProps = (props: any) => ({
  isMobile: props.width < 480
})

export default withSizes(mapSizesToProps)(RoutesList)
