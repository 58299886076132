import React, { Component } from 'react'
import { SummaryStepProps, SummaryStepState } from './types'
import Container from 'reactstrap/lib/Container'
import { Row, Col } from 'reactstrap'
import DiscountCode from '../../DiscountCode/DiscountCode'
import { currency } from 'core/utils'
import withSizes from 'react-sizes'
import classnames from 'classnames'
let Shake = (props: any) => <div {...props} />
class SummaryStep extends Component<SummaryStepProps, SummaryStepState> {
  state: SummaryStepState = {}
  componentDidMount() {
    import('react-motions').then(p => {
      Shake = p.Shake
    })
  }

  render() {
    const {
      customer,
      departure,
      amount,
      total_amount,
      discount,
      return: returnData,
      travelType,
      passengers,
      serviceId,
      onDiscount,
      hasDiscount,
      isMobile,
      isOpen,
      onRemoveDiscount,
      defaultDiscount
    } = this.props
    const currencyDiscount = `-${currency.format(discount)}`

    let discountComponent = (
      <div className="pl-3 d-flex align-items-center justify-content-between">
        <span className="big-pink h6 font-weight-bold">Descuento {hasDiscount ? '+ Promo' : ''}</span>
        <span className="big-pink h6 font-weight-bold">{currencyDiscount}</span>
      </div>
    )
    if (hasDiscount) {
      discountComponent = <Shake>{discountComponent}</Shake>
    }

    let borderStyle

    if (!isMobile) {
      borderStyle = { borderLeft: '2px solid #d01d56', maxWidth: 450 }
    }

    return (
      <Container>
        <Row>
          <Col sm={6} xs={12}>
            <div className={classnames({ 'ml-3 pl-2 mr-auto': !isMobile })} style={borderStyle}>
              <h4 className="big-gray">Información del cliente</h4>
              <h6 className="big-blue">
                <i className="fas fa-user-alt" /> {customer ? `${customer.first_name} ${customer.last_name}` : 'Público General'}
              </h6>
              <h4 className="big-gray mt-4">Detalles de la compra</h4>
              <h5 className="big-gray font-weight-bold my-3">- Salida</h5>
              {departure.map(d =>
                d.total > 0 ? (
                  <div key={d.id} className="pl-3 d-flex align-items-center justify-content-between">
                    <span className="big-gray">
                      {d.number} {d.desc}
                    </span>
                    <span className="big-gray">{currency.format(d.total)}</span>
                  </div>
                ) : null
              )}
              {returnData && returnData.length ? (
                <>
                  <h5 className="big-gray font-weight-bold my-3">- Regreso</h5>
                  {returnData.map(d =>
                    d.total > 0 ? (
                      <div key={d.id} className="pl-3 d-flex align-items-center justify-content-between">
                        <span className="big-gray">
                          {d.number} {d.desc}
                        </span>
                        <span className="big-gray">{currency.format(d.total)}</span>
                      </div>
                    ) : null
                  )}
                </>
              ) : null}
              <h4 className="big-gray mt-4">Información del pago</h4>
              <div className="pl-3 d-flex align-items-center justify-content-between">
                <span className="big-gray h6 font-weight-bold">Importe</span>
                <span className="big-gray h6 font-weight-bold">{currency.format(amount)}</span>
              </div>
              {discountComponent}
              <hr />
              <div className="pl-3 d-flex align-items-center justify-content-between">
                <span className="big-blue h5 font-weight-bold">Importe total</span>
                <span className="big-blue h5 font-weight-bold">{currency.format(total_amount)}</span>
              </div>
            </div>
          </Col>
          <Col sm={6} xs={12} className={classnames({ 'mt-5': isMobile })}>
            <DiscountCode
              passengers={passengers}
              serviceId={serviceId}
              amount={amount}
              stl={departure.filter(d => d.number > 0).map(d => ({ id: d.id, number: d.number }))}
              customer={customer}
              travelType={travelType}
              onConfirm={onDiscount}
              isOpen={isOpen}
              onRemoveDiscount={onRemoveDiscount}
              default={defaultDiscount}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapSizesToProps = (props: any) => ({
  isMobile: props.width < 480
})

export default withSizes(mapSizesToProps)(SummaryStep)
