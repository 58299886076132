import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { SeatsPickerStepProps, SeatsPickerStepStyle, SeatsPickerStepState } from './types'
import { Row, Col, Spinner, Container } from 'reactstrap'
import BusMap from '../../BusMap/BusMap'
import { PassengerSeat } from '../..'
import { Passenger } from 'modules/travel/models'
import { BusSeat, SeatType } from 'modules/travel/models/ScheduleInfo'

const styles: SeatsPickerStepStyle = () => ({
  root: {}
})

const allSeatsSelected = (passengers: Passenger[], isReturn?: boolean) => passengers.every(p => testSelectedSeat(p, isReturn))

const testSelectedSeat = (passenger: Passenger, isReturn?: boolean) => {
  const seat = isReturn ? passenger.return_seat : passenger.departure_seat

  return Boolean(seat && seat.selected)
}

const clearPassenger = (passenger: Passenger, isReturn?: boolean) => {
  if (isReturn) {
    if (passenger.return_seat) {
      passenger.return_seat.selected = false
      delete passenger.return_seat
    }
  } else {
    if (passenger.departure_seat) {
      passenger.departure_seat.selected = false
      delete passenger.departure_seat
    }
  }
}

class SeatsPickerStep extends Component<SeatsPickerStepProps, SeatsPickerStepState> {
  static getDerivedStateFromProps(nextProps: SeatsPickerStepProps, prevState: SeatsPickerStepState) {
    const newState: any = {}
    if (nextProps.bus && !prevState.bus) {
      newState.bus = nextProps.bus
    }
    if (nextProps.passengers && !prevState.passengers) {
      newState.passengers = nextProps.passengers
    }
    return newState
  }

  state: SeatsPickerStepState = {}

  componentDidMount() {
    const { passengers } = this.state
    if (passengers && passengers.length) {
      this.handleSelectPassenger(passengers[0])
    }
  }

  handleSelectPassenger = (passenger: Passenger) => {
    const { isReturn, onInvalid, bus } = this.props
    clearPassenger(passenger, isReturn)

    if (onInvalid) {
      onInvalid()
    }

    this.setState({ bus, currentPassenger: passenger })
  }

  handleSelectSeat = (seat: BusSeat) => {
    const { currentPassenger, passengers, bus } = this.state
    const { isReturn, onValid } = this.props

    if (seat.selected && passengers) {
      let passenger
      if (isReturn) {
        passenger = passengers.find(p => Boolean(p.return_seat && p.return_seat.seat === seat.seat))
      } else {
        passenger = passengers.find(p => Boolean(p.departure_seat && p.departure_seat.seat === seat.seat))
      }

      if (passenger) {
        this.handleSelectPassenger(passenger)
      }
      return
    }

    if (!(currentPassenger && passengers)) {
      return
    }

    if (!currentPassenger || seat.type === SeatType.busy) {
      return
    }

    seat.selected = true
    const newState: any = {
      bus,
      passengers,
      currentPassenger: undefined
    }
    if (isReturn) {
      currentPassenger.return_seat = seat
    } else {
      currentPassenger.departure_seat = seat
    }

    const allSelected = allSeatsSelected(passengers, isReturn)

    if (allSelected && onValid) {
      onValid(passengers)
    } else {
      let currentIndex = currentPassenger.id
      if (currentIndex === passengers.length - 1) {
        currentIndex = 0
      } else {
        currentIndex = currentIndex + 1
      }
      for (let i = currentIndex, j = passengers.length; i < j; i += 1) {
        const passenger = passengers.find(p => p.id === currentIndex)
        if (passenger) {
          if (isReturn && passenger.return_seat) {
            continue
          } else if (!isReturn && passenger.departure_seat) {
            continue
          } else {
            newState.currentPassenger = passenger
          }
        }
      }
    }

    this.setState(newState)
  }

  render() {
    const { busLoading, isReturn } = this.props
    const { currentPassenger, passengers, bus } = this.state
    let currentDiv
    if (currentPassenger && passengers && passengers.length) {
      const seat = isReturn ? currentPassenger.return_seat : currentPassenger.departure_seat
      currentDiv = (
        <Container fluid className="current-passenger p-3 d-block d-md-none">
          <Row>
            <Col
              xs={2}
              onClick={() => {
                let previousId = currentPassenger.id - 1
                if (previousId === -1) {
                  previousId = passengers.length - 1
                }
                const passenger = passengers.find(p => p.id === previousId)
                if (passenger) {
                  this.setState({ currentPassenger: passenger })
                }
              }}
            >
              <div className="passengers-arrows">
                <i className="fa fa-chevron-left" />
              </div>
            </Col>
            <Col xs={8} className="text-center">
              <h4 className="big-pink-op">{currentPassenger.full_name}</h4>
              <h4 className="big-gray selecting">{seat ? `Asiento ${seat.seat}` : 'No asignado'}</h4>
              <hr className="divider" />
            </Col>
            <Col
              xs={2}
              onClick={() => {
                let nextId = currentPassenger.id + 1
                if (nextId === passengers.length) {
                  nextId = 0
                }
                const passenger = passengers.find(p => p.id === nextId)
                if (passenger) {
                  this.setState({ currentPassenger: passenger })
                }
              }}
            >
              <div className="text-right passengers-arrows">
                <i className="fa fa-chevron-right ml-auto" />
              </div>
            </Col>
          </Row>
        </Container>
      )
    }
    return (
      <section>
        <h3 className="big-blue">Seleccione el asiento {!isReturn ? 'de ida' : 'de regreso'}</h3>
        <br />
        <Row className="d-md-none ">
          {currentDiv}

          <Col sm={{ size: 6, order: 0 }} xs={{ order: 1 }} md={{ size: 6, order: 1 }} className="text-center">
            <Row>
              <Col sm={4} className="ml-auto" xs={3}>
                <br />
                <br />
                <div className="seat mx-auto available_seat" />
                <p className="text-center">Asiento disponible</p>
                <br />
                <div className="seat mx-auto available_handicapped_seat" />
                <p className="text-center">Asiento especial</p>
                <br />
                <div className="seat mx-auto available_women_seat" />
                <p className="text-center">Asiento para mujeres</p>
                <br />
                <div className="seat mx-auto not_available_seat" />
                <p className="text-center">Asiento no disponible</p>
                <br />
                <div className="up-down-seats-padding">
                  <div className="seat mx-auto selected_seat" />
                </div>
                <p className="text-center">Asiento seleccionado</p>
              </Col>
              <Col sm={6} xs={9}>
                {!busLoading ? (
                  <BusMap bus={bus} onSelect={this.handleSelectSeat} selecting={Boolean(currentPassenger)} />
                ) : (
                  <Col sm={4} className="text-center">
                    <br />
                    <br />
                    <Spinner color="danger" />
                  </Col>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={{ size: 6, order: 1 }} className="mb-5 d-md-block d-none">
            <br />
            <br />
            {passengers &&
              passengers.map((p, i) => (
                <PassengerSeat
                  key={i}
                  passenger={p}
                  seat={isReturn ? p.return_seat : p.departure_seat}
                  showDivider={i === passengers.length - 1}
                  onSelect={this.handleSelectPassenger}
                  selecting={currentPassenger && currentPassenger.id === p.id}
                />
              ))}
          </Col>
        </Row>
        <Row className="d-none d-md-block">
          {currentDiv}
          <Col sm={{ size: 12, order: 0 }} xs={{ order: 1 }} className="text-center">
            <Row>
              <Col
                sm={2}
                // className="ml-auto"
                xs={2}
                md={2}
                lg={2}
              >
                <div className="seat mx-auto available_seat" />
                <p className="text-center">Asiento disponible</p>
              </Col>
              <Col sm={2} xs={2} md={2} lg={2}>
                <div className="seat mx-auto available_handicapped_seat" />
                <p className="text-center">Asiento especial</p>
              </Col>
              <Col sm={2} xs={2} md={2} lg={2}>
                <div className="seat mx-auto available_women_seat" />
                <p className="text-center">Asiento para mujeres</p>
              </Col>
              <Col sm={2} xs={2} md={2} lg={2}>
                <div className="seat mx-auto not_available_seat" />
                <p className="text-center">Asiento no disponible</p>
              </Col>
              <Col sm={2} xs={2} md={2} lg={2}>
                <div className="up-down-seats-padding">
                  <div className="seat mx-auto selected_seat" />
                </div>
                <p className="text-center">Asiento seleccionado</p>
              </Col>
            </Row>
          </Col>

          <Col className="testimonial-group">
            {passengers &&
              passengers.map((p, i) => (
                <PassengerSeat
                  key={i}
                  passenger={p}
                  seat={isReturn ? p.return_seat : p.departure_seat}
                  showDivider={i === passengers.length - 1}
                  onSelect={this.handleSelectPassenger}
                  selecting={currentPassenger && currentPassenger.id === p.id}
                />
              ))}
          </Col>
        </Row>
        <Row className="no_margin_bus d-none d-md-block">
          {!busLoading ? (
            <BusMap bus={bus} onSelect={this.handleSelectSeat} selecting={Boolean(currentPassenger)} />
          ) : (
            <Col sm={4} className="text-center">
              <br />
              <br />
              <Spinner color="danger" />
            </Col>
          )}
        </Row>
      </section>
    )
  }
}

export default injectSheet(styles)(SeatsPickerStep)
