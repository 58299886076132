import React, { Component } from 'react'
import { PaymentStepProps, PaymentStepStyle, PaymentStepState } from './types'
import injectSheet from 'react-jss'
import Formsy, { addValidationRule } from 'formsy-react'
import { Row, Col, Alert } from 'reactstrap'
import Config from 'core/config/Config'

import classNames from 'classnames'

import visaImg from 'img/vectores/Visa.svg'
import mcImg from 'img/vectores/MasterCard.svg'
import aeImg from 'img/vectores/AmericanExpress.svg'
import { InputFormsy, SelectFormsy, CheckboxFormsy } from 'shared/components/formsy'
import moment from 'moment'
import { ConektaService } from 'core/services'
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import { TravelService } from 'modules/travel/services'
import { Travel } from 'modules/travel/models'

addValidationRule('isCvc', (_values: any, value: string) => {
  if (!value) {
    return true
  }
  return ConektaService.validateCvc(value)
})

addValidationRule('isCardNumber', (_values: any, value: string) => {
  if (!value) {
    return true
  }
  return ConektaService.validateCardNumber(value)
})

addValidationRule('isExpiry', (values: any, _value: string) => {
  const { expMonth, expYear } = values
  if (!expMonth || !expYear) {
    return true
  }
  return ConektaService.validateExpirationDate(expMonth, expYear)
})

const styles: PaymentStepStyle = () => ({
  root: {},
  cardNumber: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center'
  },
  visa: {
    backgroundImage: `url(${visaImg})`
  },
  mastercard: {
    backgroundImage: `url(${mcImg})`
  },
  amex: {
    backgroundImage: `url(${aeImg})`
  }
})

const testTokens = [
  { card: '4012888888881881', token: 'tok_test_visa_1881' },
  { card: '5555555555554444', token: 'tok_test_mastercard_4444' },
  { card: '5105105105105100', token: 'tok_test_mastercard_5100' },
  { card: '378282246310005', token: 'tok_test_amex_0005' },
  { card: '371449635398431', token: 'tok_test_amex_8431' },
  { card: '4242424242424242', token: 'tok_test_visa_4242' },
  { card: '4000000000000002', token: 'tok_test_card_declined' },
  { card: '4000000000000127', token: 'tok_test_insufficient_funds' },
  { card: '4111111111111111', token: 'tok_test_msi_error' }
]

class PaymentStep extends Component<PaymentStepProps, PaymentStepState> {
  conekta = null
  currentMonth = parseInt(moment().format('MM'), 10)
  currentYear = parseInt(moment().format('YY'), 10)
  years: number[] = Array(16)
    .fill(this.currentYear)
    .map((item, i) => item + i)
  topRef = React.createRef<HTMLHeadingElement>()
  form: any
  state: PaymentStepState

  constructor(props: PaymentStepProps) {
    super(props)
    this.state = {
      paymentType: 'card',
      months: Array(12)
        .fill(1)
        .map((item, i) => item + i),
      selectedYear: this.currentYear,
      mercadoPagoPreferenceId: ''
    }
    initMercadoPago(Config.mercadoPagoPublilcKey)
  }

  componentDidMount() {
    ConektaService.getConekta()
      .then(conekta => {
        this.conekta = conekta
      })
      .catch(err => {
        console.error(err)
      })
    this.createPreferenceMercadoPago()
      .then(preferenceId => {
        this.setState({
          mercadoPagoPreferenceId: preferenceId
        })
      })
      .catch(err => {
        console.error(err)
        this.setState({})
      })
  }

  handlePaymentType = (_: any, value: string) => {
    this.setState({ paymentType: value })
  }

  handleCardNumberChange = (card: string) => {
    this.setState({ cardType: ConektaService.getCardBrand(card) })
  }

  handleValidForm = () => {
    const { expMonth, expYear, cardNumber, cvc, holder } = this.form.getModel()
    const { onValid } = this.props

    const test = testTokens.find(t => cardNumber === t.card)
    if (test) {
      if (onValid) {
        onValid({ holder, token: { id: test.token } })
      }
    } else {
      ConektaService.tokenize(
        cardNumber,
        holder,
        expMonth,
        expYear,
        cvc,
        (token: any) => {
          if (onValid) {
            onValid({
              token,
              holder
            })
          }
        },
        (err: any) => {
          console.error(err)
        }
      )
    }
  }

  getBodyFinishReservation(travel: Travel) {
    let discount
    const customerId = travel.customer && travel.customer.id
    if (travel.discountInfo) {
      discount = {
        rule: travel.discountInfo.discount.rule,
        date: moment().format('YYYY-MM-DD'),
        customer_id: customerId,
        discount_code: travel.discountInfo.discount.discount_code,
        service: 'boardingpass',
        special_ticket_id: travel.discountInfo.discount.apply_to_special_tickets.split(',').map(st => parseInt(st, 10)),
        num_products: travel.discountInfo.products.length,
        purchase_origin: Config.origin
      }
    }
    return {
      discount,
      customer_id: customerId,
      boardingpass_id: travel.id,
      payments: [
        {
          amount: travel.travelCost()
        }
      ]
    }
  }

  createPreferenceMercadoPago = () => {
    const { travel } = this.props
    const title = `Viaje ${travel?.return.date ? 'redondo ' : 'sencillo '}
      ${travel?.origin.prefix} - ${travel?.destination.prefix}, ${travel?.passengers.length} pasajero(s)`
    const body = {
      title,
      description: `${travel?.passengers.length} boleto(s)`,
      picture_url: 'https://i.pinimg.com/474x/10/d2/56/10d2568d56714adeaf7cb0d9bf7cda70.jpg',
      category: 'boarding_pass',
      quantity: 1,
      currency: 'MXN',
      unit_price: String(travel?.travelCost()),
      host: window.location.host,
      reservation_code: travel?.reservationCode,
      boarding_pass_id: travel?.id,
      metadata: travel && this.getBodyFinishReservation(travel)
    }
    return TravelService.createPreferenceMercadoPago(body)
      .then(data => {
        return Promise.resolve(data.id)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

  render() {
    const { mercadoPagoPreferenceId, paymentType, cardType, months } = this.state
    const { classes = {}, onInvalid, helperText, messageRef } = this.props
    return (
      <section className="w-75 mx-auto">
        <h3 className="big-blue" ref={messageRef}>
          Finaliza tu compra
        </h3>
        <br />
        <div className="w-75 mx-auto">
          {helperText ? (
            <Alert color="danger" className="d-inline-block">
              {helperText}
            </Alert>
          ) : null}
        </div>
        <Formsy
          ref={(node: any) => {
            this.form = node
          }}
          onInvalid={onInvalid}
          onValid={this.handleValidForm}
        >
          <Row>
            {mercadoPagoPreferenceId && mercadoPagoPreferenceId !== '' ? (
              <Col sm={6}>
                <br />
                <h4 className="big-gray">Pagar con Mercado Pago</h4>
                <Wallet
                  initialization={{ preferenceId: mercadoPagoPreferenceId, redirectMode: 'modal' }}
                  customization={{
                    visual: {
                      buttonBackground: 'default',
                      valuePropColor: 'white'
                    },
                    texts: {
                      action: 'pay',
                      valueProp: 'security_safety'
                    }
                  }}
                  onError={e => {
                    console.error(e)
                  }}
                />
              </Col>
            ) : null}
            {paymentType === 'card' ? (
              this.conekta ? (
                <React.Fragment>
                  <Col sm={12}>
                    <br />
                    <h4 className="big-gray">Datos de su tarjeta</h4>
                    <br />
                    <Row style={{ paddingTop: 8 }}>
                      <Col sm={12}>
                        <InputFormsy
                          label="Nombre del propietario"
                          name="holder"
                          inputProps={{
                            placeholder: 'Ingresa el nombre del propietario de la tarjeta',
                            wrapperClassName: 'blueplace'
                          }}
                          validations={{ isSpecialWords: true }}
                          validationErrors={{ isSpecialWords: 'Este campo solo acepta letras' }}
                          required
                        />
                      </Col>
                      <Col sm={6}>
                        <br />
                        <InputFormsy
                          label="Número de tarjeta:"
                          name="cardNumber"
                          onChange={this.handleCardNumberChange}
                          inputProps={{
                            placeholder: 'Ingresa el número de tu tarjeta',
                            wrapperClassName: 'blueplace',
                            className: classNames(classes.cardNumber, classes[cardType || ''])
                          }}
                          validations={{ isCardNumber: true }}
                          validationErrors={{ isCardNumber: 'El número de su tarjeta es inválido' }}
                          required
                        />
                      </Col>
                      <Col sm={6}>
                        <br />
                        <InputFormsy
                          label="Código de seguridad (CVC):"
                          name="cvc"
                          inputProps={{
                            type: 'password',
                            placeholder: 'Ingresa el código de seguridad de tu tarjeta',
                            wrapperClassName: 'blueplace'
                          }}
                          validations={{ isCvc: true }}
                          validationErrors={{ isCvc: 'El código de seguridad es inválido' }}
                          required
                        />
                      </Col>
                      <Col sm={6}>
                        <br />
                        <SelectFormsy
                          label="Fecha de expiración"
                          name="expMonth"
                          selectProps={{
                            className: 'custom-select est-cd'
                          }}
                          validations={{ isExpiry: true }}
                          validationErrors={{ isExpiry: 'La fecha de expiración es inválida' }}
                          required
                          value={this.currentMonth}
                        >
                          {months.map(i => (
                            <option key={i} value={i}>
                              {i.toString().padStart(2, '0')}
                            </option>
                          ))}
                        </SelectFormsy>
                      </Col>
                      <Col sm={6}>
                        <br />
                        <SelectFormsy
                          label="KRIBLET"
                          labelClassName="invisible"
                          name="expYear"
                          selectProps={{
                            className: 'custom-select est-cd'
                          }}
                          required
                          value={this.years[0]}
                        >
                          {this.years.map(i => (
                            <option key={i} value={i}>
                              {i.toString().padStart(2, '0')}
                            </option>
                          ))}
                        </SelectFormsy>
                      </Col>
                      <div className="col-sm-12">
                        <br />
                        <br />
                        <CheckboxFormsy
                          label={
                            <React.Fragment>
                              He leído y acepto los
                              <a href="/terminos" target="blank">
                                {' '}
                                <b>términos y condiciones.</b>
                              </a>
                            </React.Fragment>
                          }
                          labelClassName="h6"
                          name="termsAccepted"
                          validations={{ isTrue: true }}
                          validationErrors={{ isTrue: 'Debe aceptar los terminos para continuar con el pago' }}
                          required
                        />
                      </div>
                    </Row>
                    <br />
                    <p className="label">* Campos obligatorios</p>
                  </Col>
                </React.Fragment>
              ) : null
            ) : null}
          </Row>
        </Formsy>
      </section>
    )
  }
}

export default injectSheet(styles)(PaymentStep)
